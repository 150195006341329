import { computed, ref, watch } from '@vue/composition-api'
import useTableHelper from '@/helpers/useTableHelper'
import useHttpService from '@/http/useHttpService'

import ToastificationContent from '@core/components/toastification/ToastificationContent'

import { useToast } from 'vue-toastification/composition'
import { title, formatDate, formatDateToMonthShort } from '@core/utils/filter'

export default function useJobsList(jobStatus) {

  const resolveRecruiterTypeText = text => {
    if(!text) return text;
    const recruiter = text.replace('_', ' ').toLowerCase()
    return recruiter.charAt(0).toUpperCase() + recruiter.slice(1)
  }

  const {jobs} = useHttpService()
  const toast = useToast()
  const fieldOfWorks = ref([])
  const selectedFieldOfWork = ref(null)
  const selectedCategory = ref(null)
  const selectedJobPosition = ref(null)
  const viewsRange = ref(null)

  function getJobFilters() {
    jobs.getFilters().then(response => {
      fieldOfWorks.value = response.fieldOfWorks
    })
  }

  function onFieldOfWorkChanged(event) {
    selectedFieldOfWork.value = event
  }

  function onJobPositionChange(event) {
    selectedJobPosition.value = event
  }

  function onJobCategoryChange(event) {
    selectedCategory.value = event
  }

  getJobFilters()

  const jobPublishedFilter = ref(null)

  const {
    refTable,

    perPage,
    totalCount,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,

    dataMeta,
    refetchData
  } = useTableHelper()

  const tableColumns = [
    {key: 'workPosition', label: 'Name'},
    {key: 'name', label: 'User'},
    {key: 'userType', label: 'User type', formatter: resolveRecruiterTypeText},
    {key: 'created', label: 'Date'},
    {key: 'status', label: 'Status'},
    {key: 'applicationsCount', label: 'Crew Applied'},
    {key: 'views'},
    {key: 'actions'}
  ]

  const advancedFilters = computed(() => {
    const [jobPublishedFrom, jobPublishedUntil] = jobPublishedFilter.value !== null  ? jobPublishedFilter.value.split(' to ') : []
    const [viewsFromDate, viewsToDate] = viewsRange.value !== null  ? viewsRange.value.split(' to ') : []

    return {
      jobPublishedFrom,
      jobPublishedUntil,
      viewsFromDate,
      viewsToDate,
      fieldOfWorks: selectedFieldOfWork.value,
      workPositionGroups: selectedCategory.value,
      workPositions: selectedJobPosition.value,
    }
  })

  // if(jobStatus) {
  //   tableColumns.push({ key: 'actions' })
  // }

  watch([currentPage, perPage, selectedFieldOfWork, selectedJobPosition, selectedCategory], () => {
    refetchData()
  })

  watch([searchQuery, jobPublishedFilter, viewsRange], () => {
    currentPage.value = 1;
    refetchData();
  })

  const fetchJobs = (ctx, callback) => {

    jobs.getJobs(currentPage.value, perPage.value, searchQuery.value, jobStatus, advancedFilters.value)
      .then(response => {
        callback(response.results)
        totalCount.value = response.totalCount
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching jobs list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  function exportJobs() {
    jobs.exportJobsToExel(currentPage.value, perPage.value, searchQuery.value, jobStatus, advancedFilters.value).then(response => {
      let blob = new Blob([response], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
      let url = window.URL.createObjectURL(blob);
      window.location.href = url;
    })
  }

  function deleteJob(uid) {
    jobs.deleteJob(uid).then(response => {
      refetchData();
      toast({
        component: ToastificationContent,
        props: {
          title: 'Job has been deleted!',
          variant: 'success',
        },
      })
    })
  }

  function verifyJob(uid) {
    jobs.verifyJob(uid).then(() => {
      refetchData();
    })
  }

  const resolveJobStatusColor = status => {
    if(status === 'VERIFIED') return 'success'
    if(status === 'DISABLED') return 'primary'
    if(status === 'DRAFT') return 'info'
    if(status === 'PUBLISHED') return 'warning'
    return 'primary'
  }

  const resolveJobLink = (job) => {
    const url = process.env['VUE_APP_REDIRECT_LINK']
    return `${url}/jobs/${job.fieldOfWork.replaceAll("/","")}/${job.workPosition}/${job.uid}`
  }


  return {
    refTable,

    perPage,
    totalCount,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    jobPublishedFilter,
    fieldOfWorks,

    fetchJobs,
    verifyJob,
    exportJobs,
    deleteJob,
    onFieldOfWorkChanged,
    onJobPositionChange,
    onJobCategoryChange,

    resolveJobStatusColor,
    resolveJobLink,

    dataMeta,
    refetchData,
    viewsRange
  }
}
